<template>
  <div>
      <div class="px-2">

        <div class="row d-flex align-items-center">

          <div class="col-md-4 mb-1 mb-md-0">
            <div class="">
              <label for="online_discount_name">Discount Rate (%)</label>
              <input type="text" class="form-control" placeholder="" v-model="currentSetting.value.discount_rate" id="online_discount_name">
            </div>
          </div>

          <div class="col-md-4 mb-1 mb-md-0">
            <div class="">
              <label class="">Max Discount Amount (in cent)</label>
              <input type="text" class="form-control" placeholder="" v-model="currentSetting.value.max_discount_amount" name="online_discount_amount">
            </div>
          </div>

          <div class="col-md-2 mb-1 mb-md-0">
            <div class="form-group">
              <div class="controls">
                <label>Current Status</label>
                <div style="margin-top: .5rem">
                  <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                    <div>
                      <input v-model="checkBoxStatus" type="checkbox" class="custom-control-input"  :id="currentSetting.key">
                      <label class="custom-control-label mr-1" :for="currentSetting.key">
                        <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                        <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                      </label>
                    </div>
                    <span class="font-medium-1 text-bold-600">{{checkBoxStatus === true ? "Active" : "Inactive"  }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div>
              <button :disabled="isUpdateButtonEnable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default  {
  name: "SettingAppointmentOnlineAppointmentDiscount",
  mixins: [ShowToastMessage, Loader],
  props: ['appointmentOnlineAppointmentDiscount'],
  data() {
    return {
      currentSetting: this.appointmentOnlineAppointmentDiscount,
      checkBoxStatus: false,
    };
  },
  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    }
  },
  watch:{
    'currentSetting.value.status'(newValue){
      this.checkBoxStatus = newValue == 1;
    },
    checkBoxStatus(newValue){
      this.currentSetting.value.status = (newValue === true ? 1 : 0);
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Appointment online appointment discount updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>