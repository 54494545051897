<template>
  <div>
    <div class="px-2">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="controls">
              <label>Current Status</label>
              <div style="margin-top: .5rem">
                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                  <div>
                    <input v-model="timeBaseCheckBoxStatus" type="checkbox" class="custom-control-input" checked
                           :id="currentSetting.key">
                    <label class="custom-control-label mr-1" :for="currentSetting.key">
                      <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                      <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                    </label>
                  </div>
                  <span class="font-medium-1 text-bold-600">{{
                      timeBaseCheckBoxStatus === true ? "Active" : "Inactive"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="w-100 mr-1 my-1">
            <div class="d-flex justify-content-end">
              <button :disabled="isUpdateButtonEnable" class="btn btn-sm btn-primary ml-2" type="submit">Add</button>
            </div>
          </div>
        </div>

      </div>
      <div class="row mb-1">
        <div v-for="(timebaseSurcharge,index) in timebaseSurchargeList" :key="index"
             class="col-12 border rounded border-light mb-1">
          <div class="d-md-flex w-100 align-items-center">

            <div class="col-12 col-lg-2 col-md-2 rate-input-class">
              <label :for="`rate_${index}`">Rate</label>
              <input type="number" class="form-control" placeholder="Enter rate" v-model="timebaseSurcharge.rate"
                     :id="`rate_${index}`">
            </div>

            <div class="col-12 col-lg-3 col-md-3">
              <label :for="`appointmentStartTime_${index}`">Start Time</label>
              <input v-model="timebaseSurcharge.start_time" type="text" class="form-control"
                     :id="`appointmentStartTime_${index}`"
                     placeholder="Appointment Start Time">
              <p class="text-muted">Input format : 00:00 (H:M) </p>
            </div>

            <div class="col-12 col-lg-3 col-md-3">
              <label :for="`appointmentEndTime_${index}`">End Time</label>
              <input v-model="timebaseSurcharge.end_time" type="text" class="form-control"
                     :id="`appointmentEndTime_${index}`"
                     placeholder="Appointment End Time">
              <p class="text-muted">Input format : 00:00 (H:M) </p>
            </div>

            <div class="col-12 col-lg-3 col-md-3 rate-input-class">
              <div class="form-group">
                <div class="controls">
                  <label class="mt-1"> Status</label>
                  <div style="margin-top: .6rem">
                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                      <div>
                        <input v-model="detailsStatus[index]" type="checkbox" class="custom-control-input" checked
                               :id="`timebaseSurcharge-${index}`">
                        <label class="custom-control-label mr-1" :for="`timebaseSurcharge-${index}`">
                          <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                          <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                        </label>
                      </div>
                      <span class="font-medium-1">{{
                          (timebaseSurcharge.status) === 1 ? "Active" : "Inactive"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-1 col-md-1 cross-button-mrg-style">
              <button class="btn btn-sm btn-danger"><i class='bx bx-x'></i></button>
            </div>
          </div>
        </div>


        <div class="d-flex w-100 align-items-center justify-content-end px-2">
          <div>
            <button @click="updateSettingsHandler" class="btn btn-primary " type="submit">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "SettingAppointmentTimebaseSurcharge",
  mixins: [ShowToastMessage, Loader],
  props: ['appointmentTimebaseSurcharge'],
  data() {
    return {
      currentSetting: this.appointmentTimebaseSurcharge,
      timeBaseCheckBoxStatus: 0,
      detailsStatus: [],
      timebaseSurchargeList: this.appointmentTimebaseSurcharge?.value?.details ?? [],
    };
  },

  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    }
  },
  watch: {
    'currentSetting.value.status': {
      handler(newValue) {
        this.timeBaseCheckBoxStatus = newValue == 1;
      },
      immediate: true,
    },
    timebaseSurchargeList: {
      handler(newValue) {
        newValue.forEach((item, index) => {
          this.detailsStatus[index] = item.status == 1;
        })
      },
      deep: true,
      immediate: true
    },
    detailsStatus: {
      handler(newValue) {
        newValue.forEach((item, index) => {
          this.currentSetting.value.details[index].status = item === true ? 1 : 0;
        })
      },
      deep: true
    },
    timeBaseCheckBoxStatus(appStatus) {
      this.currentSetting.value.status = (appStatus === true ? 1 : 0);
    }
  },

  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      dataObj.data.value.details = this.currentSetting.value.details.map((item) => {
        const {status, rate, start_time, end_time} = item;
        return {
          status: status,
          rate: rate,
          start_time: start_time,
          end_time: end_time,
        }
      });

      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Appointment timebase surcharge updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>
.rate-input-class {
  margin-bottom: 38px;
}

.cross-button-mrg-style {
  margin: 0px 0px 38px 0px;
}
</style>