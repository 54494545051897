<template>
  <div>
    <div class="px-2">
      <div class="">
        <div class="row d-md-flex w-100 justify-content-between mb-1">
          <div class="col-12 col-md-6 col-lg-6 py-1 mb-0 mb-md-0">
            <div class="form-group">
              <div class="controls">
                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                  <div>
                    <input v-model="checkBoxStatus" type="checkbox" class="custom-control-input"
                           :id="currentSetting.key">
                    <label class="custom-control-label mr-1" :for="currentSetting.key">
                      <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                      <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                    </label>
                  </div>
                  <span class="font-medium-1 text-bold-600">{{
                      checkBoxStatus === true ? "Active" : "Inactive"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-2 mx-md-3 d-flex d-md-flex justify-content-center justify-content-md-start py-2">
            <button :disabled="isUpdateButtonEnable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2"
                    type="submit">Update
            </button>
          </div>
        </div>
        <div class="row box">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label>Select date</label>
              <div class="d-flex align-items-center date-picker-wrapper">
                <div class="w-100" ref="picker">
                  <DatePicker
                      :model-value="modelValue"
                      :attributes="attributes"
                      :update-on-input="true"
                      :min-date="new Date()"
                      highlight="red"
                      :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}"
                      @dayclick.self="onDayClick"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="h-100 pb-3 col-12 col-md-6 col-lg-3">
            <h6>Selected Date</h6>
            <div class="h-100 p-1 border border-lighten-4 rounded"> {{ dates.join(', ') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from "v-calendar";
import globalMixin from "@/components/backEnd/mixins/GlobalMixin";


export default {
  name: "SettingAppointmentSameDayBookingStatus",
  mixins: [ShowToastMessage, Loader, globalMixin],
  components: {
    DatePicker,
  },
  props: ['appointmentSameDayBookingStatus'],
  data() {
    return {
      currentSetting: this.appointmentSameDayBookingStatus,
      checkBoxStatus: 0,
      modelValue: {},
      days: [],
    };
  },
  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    },
    dates() {
      return this.days.map(day => this.getDateTime(day.date, 1));
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: 'red',
        dates: date,
      }));


    },
  },
  watch: {
    'currentSetting.value'(newValue) {
      this.checkBoxStatus = newValue.status == 1;
      this.days = newValue.days.map(day => {
        return {
          id: day,
          date: new Date(day),
        };
      });
    },
    checkBoxStatus(newValue) {
      this.currentSetting.value.status = (newValue === true ? 1 : 0);
    },

  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),
    onDayClick(day) {
      const idx = this.days.findIndex(d => d.id == day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }


    },
    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      this.currentSetting.value.days = this.dates;
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Appointment same day booking status updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>