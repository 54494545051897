<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="appointment-setting-wrapper">
          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="">Appointment/List</h4>
          </div>

          <div class="accordion" id="appointmentAccordion">

            <div class="card">
              <div class="card-header" id="headingReferencePrefix">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseReferencePrefix" aria-expanded="false"
                        aria-controls="collapseReferencePrefix">

                  <span class="text-capitalize appointment-setting-title">Appointment Reference Prefix</span>

                </button>
              </div>
              <div id="collapseReferencePrefix" class="collapse" aria-labelledby="headingReferencePrefix"
                   data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentReferencePrefix :appointment-reference-prefix="this.appointmentReferencePrefix"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingReferenceSuffix">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseReferenceSuffix" aria-expanded="false"
                        aria-controls="collapseReferenceSuffix">
                  <span class="text-capitalize appointment-setting-title">Appointment Reference Suffix</span>
                </button>

              </div>
              <div id="collapseReferenceSuffix" class="collapse" aria-labelledby="headingReferenceSuffix"
                   data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentReferenceSuffix :appointment-reference-suffix="this.appointmentReferenceSuffix"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOnlineAppointmentStatus">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOnlineAppointmentStatus" aria-expanded="false"
                        aria-controls="collapseOnlineAppointmentStatus">
                  <span class="text-capitalize appointment-setting-title">Appointment Online Booking Status</span>
                </button>

              </div>
              <div id="collapseOnlineAppointmentStatus" class="collapse"
                   aria-labelledby="headingOnlineAppointmentStatus" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingOnlineAppointmentStatus :online-appointment-status="this.appointmentOnlineBookingStatus"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentSameDayBookingStatus">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentSameDayBookingStatus" aria-expanded="false"
                        aria-controls="collapseAppointmentSameDayBookingStatus">
                  <span class="text-capitalize appointment-setting-title">Appointment Same Day Booking Status</span>
                </button>

              </div>
              <div id="collapseAppointmentSameDayBookingStatus" class="collapse"
                   aria-labelledby="headingAppointmentSameDayBookingStatus" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentSameDayBookingStatus :appointment-same-day-booking-status="this.appointmentSameDayBookingStatus"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOnlineAppointmentSameDaySurcharge">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOnlineAppointmentSameDaySurcharge" aria-expanded="false"
                        aria-controls="collapseOnlineAppointmentSameDaySurcharge">

                  <span class="text-capitalize appointment-setting-title">Appointment Same Day Surcharge</span>

                </button>
              </div>
              <div id="collapseOnlineAppointmentSameDaySurcharge" class="collapse"
                   aria-labelledby="headingOnlineAppointmentSameDaySurcharge" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentSameDaySurcharge
                      :appointment-same-day-surcharge="this.appointmentSameDaySurcharge"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOnlineAppointmentHolidaySurcharge">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOnlineAppointmentHolidaySurcharge" aria-expanded="false"
                        aria-controls="collapseOnlineAppointmentHolidaySurcharge">
                  <span class="text-capitalize appointment-setting-title">Appointment Holiday Surcharge</span>
                </button>

              </div>
              <div id="collapseOnlineAppointmentHolidaySurcharge" class="collapse"
                   aria-labelledby="headingOnlineAppointmentHolidaySurcharge" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentHolidaySurcharge
                      :appointment-holiday-surcharge="this.appointmentHolidaySurcharge"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOnlineAppointmentTimebaseSurcharge">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOnlineAppointmentTimebaseSurcharge" aria-expanded="false"
                        aria-controls="collapseOnlineAppointmentTimebaseSurcharge">

                  <span class="text-capitalize appointment-setting-title">Appointment Timebase Surcharge</span>

                </button>
              </div>
              <div id="collapseOnlineAppointmentTimebaseSurcharge" class="collapse"
                   aria-labelledby="headingOnlineAppointmentTimebaseSurcharge" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentTimebaseSurcharge
                      v-if="this.appointmentTimebaseSurcharge?.value?.details?.length > 0"
                      :appointment-timebase-surcharge="this.appointmentTimebaseSurcharge"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingOnlineAppointmentServiceDuration">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseOnlineAppointmentServiceDuration" aria-expanded="false"
                        aria-controls="collapseOnlineAppointmentServiceDuration">
                  <span class="text-capitalize appointment-setting-title">Appointment Service Duration</span>
                </button>

              </div>
              <div id="collapseOnlineAppointmentServiceDuration" class="collapse"
                   aria-labelledby="headingOnlineAppointmentServiceDuration" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentServiceDuration :appointment-service-duration="this.appointmentServiceDuration"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentStatus">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentStatus" aria-expanded="false"
                        aria-controls="collapseAppointmentStatus">

                  <span class="text-capitalize appointment-setting-title">Appointment Status</span>

                </button>
              </div>
              <div id="collapseAppointmentStatus" class="collapse" aria-labelledby="headingAppointmentStatus"
                   data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentStatus :setting-appointment="this.appointmentSettingAppointmentStatus"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentServicePlatform">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentServicePlatform" aria-expanded="false"
                        aria-controls="collapseAppointmentServicePlatform">
                  <span class="text-capitalize appointment-setting-title">Appointment Service Platform</span>
                </button>

              </div>
              <div id="collapseAppointmentServicePlatform" class="collapse"
                   aria-labelledby="headingAppointmentServicePlatform" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentServicePlatform :appointment-service-platform="this.appointmentServicePlatform"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentNoteType">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentNoteType" aria-expanded="false"
                        aria-controls="collapseAppointmentNoteType">

                  <span class="text-capitalize appointment-setting-title">Appointment Note Type</span>

                </button>
              </div>
              <div id="collapseAppointmentNoteType" class="collapse" aria-labelledby="headingAppointmentNoteType"
                   data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentNoteType :appointment-note-type="this.appointmentNoteType"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentOnSiteMaximumDistance">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentOnSiteMaximumDistance" aria-expanded="false"
                        aria-controls="collapseAppointmentOnSiteMaximumDistance">
                  <span class="text-capitalize appointment-setting-title">Appointment Maximum Distance</span>
                </button>

              </div>
              <div id="collapseAppointmentOnSiteMaximumDistance" class="collapse"
                   aria-labelledby="headingAppointmentOnSiteMaximumDistance" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentOnSiteMaximumDistance
                      :appointment-on-site-maximum-distance="this.appointmentOnSiteMaximumDistance"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentServiceExtraDurationUnitAndChargeRate">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentServiceExtraDurationUnitAndChargeRate" aria-expanded="false"
                        aria-controls="collapseAppointmentServiceExtraDurationUnitAndChargeRate">

                  <span class="text-capitalize appointment-setting-title">Appointment Service Extra Duration Unit And Charge Rate</span>

                </button>
              </div>
              <div id="collapseAppointmentServiceExtraDurationUnitAndChargeRate" class="collapse"
                   aria-labelledby="headingAppointmentServiceExtraDurationUnitAndChargeRate"
                   data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentServiceExtraDurationUnitAndChargeRate
                      :appointment-service-extra-duration-unit-and-charge-rate="this.appointmentServiceExtraDurationUnitAndChargeRate"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentOnlineAppointmentDiscount">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentOnlineAppointmentDiscount" aria-expanded="false"
                        aria-controls="collapseAppointmentOnlineAppointmentDiscount">
                  <span class="text-capitalize appointment-setting-title">Appointment Online Appointment Discount</span>
                </button>

              </div>
              <div id="collapseAppointmentOnlineAppointmentDiscount" class="collapse"
                   aria-labelledby="headingAppointmentOnlineAppointmentDiscount" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentOnlineAppointmentDiscount
                      :appointment-online-appointment-discount="this.appointmentOnlineAppointmentDiscount"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentOnlineAppointmentWithoutPaymentStatus">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentOnlineAppointmentWithoutPaymentStatus" aria-expanded="false"
                        aria-controls="collapseAppointmentOnlineAppointmentWithoutPaymentStatus">

                  <span class="text-capitalize appointment-setting-title">Appointment Online Appointment Without Payment Status</span>

                </button>
              </div>
              <div id="collapseAppointmentOnlineAppointmentWithoutPaymentStatus" class="collapse"
                   aria-labelledby="headingAppointmentOnlineAppointmentWithoutPaymentStatus"
                   data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentOnlineAppointmentWithoutPaymentStatus
                      :appointment-online-appointment-without-payment-status="this.appointmentOnlineAppointmentWithoutPaymentStatus"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentFuelLevySurcharge">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentFuelLevySurcharge" aria-expanded="false"
                        aria-controls="collapseAppointmentFuelLevySurcharge">
                  <span class="text-capitalize appointment-setting-title">Appointment Fuel Levy Surcharge</span>
                </button>

              </div>
              <div id="collapseAppointmentFuelLevySurcharge" class="collapse"
                   aria-labelledby="headingAppointmentFuelLevySurcharge" data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentFuelLevySurcharge
                      :appointment-fuel-levy-surcharge="this.appointmentFuelLevySurcharge"/>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingAppointmentOnlineAppointmentWithPartialPaymentStatus">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseAppointmentOnlineAppointmentWithPartialPaymentStatus" aria-expanded="false"
                        aria-controls="collapseAppointmentOnlineAppointmentWithPartialPaymentStatus">

                  <span class="text-capitalize appointment-setting-title">Appointment Online Appointment With Partial Payment Status</span>

                </button>
              </div>
              <div id="collapseAppointmentOnlineAppointmentWithPartialPaymentStatus" class="collapse"
                   aria-labelledby="headingAppointmentOnlineAppointmentWithPartialPaymentStatus"
                   data-parent="#appointmentAccordion">
                <div class="card-body">
                  <SettingAppointmentOnlineAppointmentWithPartialPaymentStatus
                      :appointment-online-appointment-with-partial-payment-status="this.appointmentOnlineAppointmentWithPartialPaymentStatus"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
  mapActions,
  mapGetters
} from 'vuex';
import SettingAppointmentReferencePrefix
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentReferencePrefix";
import SettingAppointmentReferenceSuffix
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentReferenceSuffix";
import SettingOnlineAppointmentStatus
  from "@/views/backEnd/settings/appointments/includes/SettingOnlineAppointmentStatus";
import SettingAppointmentSameDaySurcharge
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentSameDaySurcharge";
import SettingAppointmentHolidaySurcharge
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentHolidaySurcharge";
import SettingAppointmentTimebaseSurcharge
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentTimebaseSurcharge";
import SettingAppointmentServiceDuration
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentServiceDuration";
import SettingAppointmentStatus from "@/views/backEnd/settings/appointments/includes/SettingAppointmentStatus";
import SettingAppointmentServicePlatform
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentServicePlatform";
import SettingAppointmentNoteType from "@/views/backEnd/settings/appointments/includes/SettingAppointmentNoteType";
import SettingAppointmentOnSiteMaximumDistance
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentOnSiteMaximumDistance";
import SettingAppointmentServiceExtraDurationUnitAndChargeRate
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentServiceExtraDurationUnitAndChargeRate";
import SettingAppointmentOnlineAppointmentDiscount
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentOnlineAppointmentDiscount";
import SettingAppointmentOnlineAppointmentWithoutPaymentStatus
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentOnlineAppointmentWithoutPaymentStatus";
import SettingAppointmentFuelLevySurcharge
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentFuelLevySurcharge";
import SettingAppointmentSameDayBookingStatus
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentSameDayBookingStatus.vue";
import SettingAppointmentOnlineAppointmentWithPartialPaymentStatus
  from "@/views/backEnd/settings/appointments/includes/SettingAppointmentOnlineAppointmentWithPartialPaymentStatus.vue";
export default {
  name: "SettingAppointmentList",
  components: {
    AppLayout,
    SettingAppointmentReferencePrefix,
    SettingAppointmentReferenceSuffix,
    SettingOnlineAppointmentStatus,
    SettingAppointmentSameDaySurcharge,
    SettingAppointmentHolidaySurcharge,
    SettingAppointmentTimebaseSurcharge,
    SettingAppointmentServiceDuration,
    SettingAppointmentStatus,
    SettingAppointmentServicePlatform,
    SettingAppointmentNoteType,
    SettingAppointmentOnSiteMaximumDistance,
    SettingAppointmentServiceExtraDurationUnitAndChargeRate,
    SettingAppointmentOnlineAppointmentDiscount,
    SettingAppointmentOnlineAppointmentWithoutPaymentStatus,
    SettingAppointmentOnlineAppointmentWithPartialPaymentStatus,
    SettingAppointmentFuelLevySurcharge,
    SettingAppointmentSameDayBookingStatus,

  },
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      getSettingsParams: {
        type: ["appointment"],

        key: [
          "appointment_reference_prefix",
          "appointment_reference_suffix",
          "appointment_online_booking_status",
          "appointment_same_day_surcharge",
          "appointment_holiday_surcharge",
          "appointment_service_duration",
          "appointment_status",
          "appointment_service_platform",
          "appointment_note_type",
          "appointment_timebase_surcharge",
          "appointment_on_site_maximum_distance",
          "appointment_service_extra_duration_unit_and_charge_rate",
          "appointment_online_appointment_discount",
          "appointment_online_appointment_without_payment_status",
          "appointment_online_appointment_with_partial_payment_status",
          "appointment_fuel_levy_surcharge",
          "appointment_same_day_booking_status",
        ],
      },
      currentState: {},
      frontEndErrors: [],
    }
  },

  computed: {
    ...mapGetters({
      appointmentReferencePrefix: "appSettings/settingAppointmentReferencePrefix",
      appointmentReferenceSuffix: "appSettings/settingAppointmentReferenceSuffix",
      appointmentOnlineBookingStatus: "appSettings/settingAppointmentOnlineBookingStatus",
      appointmentSameDaySurcharge: "appSettings/settingAppointmentSameDaySurcharge",
      appointmentHolidaySurcharge: "appSettings/settingAppointmentHolidaySurcharge",
      appointmentTimebaseSurcharge: "appSettings/settingAppointmentTimebaseSurcharge",
      appointmentServiceDuration: "appSettings/settingAppointmentServiceDuration",
      appointmentSettingAppointmentStatus: "appSettings/settingAppointmentStatus",
      appointmentServicePlatform: "appSettings/settingAppointmentServicePlatform",
      appointmentNoteType: "appSettings/settingAppointmentNoteType",
      appointmentOnSiteMaximumDistance: "appSettings/settingAppointmentOnSiteMaximumDistance",
      appointmentServiceExtraDurationUnitAndChargeRate: "appSettings/settingAppointmentServiceExtraDurationUnitAndChargeRate",
      appointmentOnlineAppointmentDiscount: "appSettings/settingAppointmentOnlineAppointmentDiscount",
      appointmentOnlineAppointmentWithoutPaymentStatus: "appSettings/settingAppointmentOnlineAppointmentWithoutPaymentStatus",
      appointmentOnlineAppointmentWithPartialPaymentStatus: "appSettings/settingAppointmentOnlineAppointmentWithPartialPaymentStatus",
      appointmentFuelLevySurcharge: "appSettings/settingAppointmentFuelLevySurcharge",
      appointmentSameDayBookingStatus: "appSettings/settingAppointmentSameDayBookingStatus",
    }),

  },

  methods: {
    ...mapActions({

      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",

    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.appointment-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.appointment-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
