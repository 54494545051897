const GlobalMixin = {
    methods: {
        // convert plain string to title case
        titleCase(str) {
            return str?.toLowerCase().split(' ').map(function (word) {
                return (word.charAt(0).toUpperCase() + word.slice(1));
            }).join(' ') ?? '';
        },

        // convert underscore string to title case

        camelCase(str) {
            return str?.replace(/_([a-zA-Z])/g, function (m1, m2) {
                return m2.toUpperCase()
            }) ?? '';
        },

        allCaseToTitle(str) {
            return str.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
                .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
        },

        /**
         * @description 1 for data & time 2 for date only 3 for time
         * @param currentDate
         * @param status
         */
        getDateTime(currentDate = new Date(), status) {
            let validDate = currentDate instanceof Date && !isNaN(currentDate);
            let today = validDate ? new Date(currentDate) : new Date();
            let date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
            let time = ('0' + today.getHours()).slice(-2) + ":" + ('0' + today.getMinutes()).slice(-2) + ":" + ('0' + today.getSeconds()).slice(-2);
            if (!status)
                return date + ' ' + time;
            if (status === 1)
                return date
            if (status === 2)
                return time
        },
        getAustraliaDateTime(timezone) {
            const now = new Date();

            const options = {
                timeZone: timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };

            const formatter = new Intl.DateTimeFormat('en-GB', options);
            const formattedParts = formatter.formatToParts(now);

            const formattedDateTime = formattedParts.reduce((acc, part) => {
                switch (part.type) {
                    case 'year':
                        acc.year = part.value;
                        break;
                    case 'month':
                        acc.month = part.value;
                        break;
                    case 'day':
                        acc.day = part.value;
                        break;
                    case 'hour':
                        acc.hour = part.value;
                        break;
                    case 'minute':
                        acc.minute = part.value;
                        break;
                    case 'second':
                        acc.second = part.value;
                        break;
                }
                return acc;
            }, {});

            return `${formattedDateTime.year}-${formattedDateTime.month}-${formattedDateTime.day} ${formattedDateTime.hour}:${formattedDateTime.minute}:${formattedDateTime.second}`;
        }

    },
};

export default GlobalMixin;