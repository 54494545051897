<template>
  <div>
      <div class="px-2">

        <div class="row">
          <div class="table-responsive">
            <table id="users-list-datatable" class="table">
              <thead>
              <tr>
                <th class="position-relative" style="width: 5%">Value</th>
                <th class="position-relative" style="width: 20%">Name</th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="(servicePlatform,index) in settingAppointmentServicePlatform?.value ?? []" :key="index">
                <td>{{servicePlatform.value}}</td>
                <td>{{servicePlatform.name}}</td>

              </tr>
              </tbody>
            </table>
          </div>

          <div class="col-12">
            <div class="text-danger"><span>*For update appointment service platform, please contact with system Webmaster.</span></div>
          </div>

        </div>

      </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "SettingAppointmentServicePlatform",
  mixins: [ShowToastMessage, Loader],
  props: ['appointmentServicePlatform'],
  data() {
    return {
      currentSetting: this.appointmentServicePlatform,
      servicePlatform : [],
    };
  },
  watch:{

  },
  computed: {
    ...mapGetters({
      settingAppointmentServicePlatform: 'appSettings/settingAppointmentServicePlatform',

    }),
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    },

  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

  },


}
</script>

<style scoped>

</style>